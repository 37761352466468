import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Layout } from '../components'
import ComingSoon from '../components/comming-soon'
import { black, white } from '../styling/colors'
import { When } from '../styling/when'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { ArrowRight } from '../components/icons'
import { DecorationsSlider } from "../components";
import Img from 'gatsby-image'

const Title = styled.h1`
  color: ${black};
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;

  ${When.Large} {
    display: block;
    font-size: 48px;
    font-family: 'poppins';
    line-height: 60px;
    // margin: 50px 0 0 0;
  }
`

const SubTitle = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: ${black};
`

const LearnMoreAndArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const LearnMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 8px;
`

const LearnMoreLink = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`

const SliderContainer = styled.div`
width: 300px;
height: 300px;
margin: 0 auto;
margin-top: 36px;
position: relative;
`

const DecorationsContainer = styled.div`
height: 100%;
max-height: calc(100vh - 88px);
padding-top: 40px;
overflow: hidden;
box-sizing: border-box;
margin: auto;
position: relative;
width: 100%;
max-width: 410px;
${When.Medium} {
  height: unset;
  max-width: 900px;
  overflow: visible;
}

${When.Large} {
  max-width: unset;
}
`

const ImgWrapper = (props) => <div {...props}><Img fluid={props.fluid} /></div>;


const BgImage1 = styled(ImgWrapper)`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 10%;
  top: 418px;

${When.Medium} {
  position: absolute;
  width: 96px;
  height: 96px;
  left: 130px;
  top: 120px;
}

${When.Large} {
  position: absolute;
  width: 96px;
  height: 96px;
  top: 60px;
  left: unset;
}
  border-radius: 100%;
`
const BgImage2 = styled(ImgWrapper)`
position: absolute;
width: 128px;
height: 128px;
right: 5%;
top: 495px;

${When.Medium} {
  position: absolute;
  width: 128px;
  height: 128px;
  left: 19px;
  top: 267px;
}

${When.Large} {
  position: absolute;
  width: 128px;
  height: 128px;
  left: 130px;
  top: 140px;
}
  border-radius: 100%;
`
const BgImage3 = styled(ImgWrapper)`
${When.Medium} {
  position: absolute;
  width: 160px;
  height: 160px;
  left: 140px;
  top: 411px;
}

${When.Large} {
  position: absolute;
  width: 160px;
  height: 160px;
  left: -2px;
  top: 312px;
}
  border-radius: 100%;
`
const BgImage4 = styled(ImgWrapper)`
${When.Medium} {
  position: absolute;
  width: 128px;
  height: 128px;
  right: 60px;
  top: 140px;
}

${When.Large} {
  position: absolute;
  width: 128px;
  height: 128px;
  // left: 1109px;
  right: 37px;
  top: 62px;
}
  border-radius: 100%;
`
const BgImage5 = styled(ImgWrapper)`
${When.Medium} {
  position: absolute;
  width: 160px;
  height: 160px;
  right: 43px;
  top: 332px;
}
${When.Large} {
  position: absolute;
  width: 96px;
  height: 96px;
  right: 216px;
  top: 172px;
}
border-radius: 100%;
`
const BgImage6 = styled(ImgWrapper)`
${When.Large} {
  position: absolute;
  width: 160px;
  height: 160px;
  right: 37px;
  top: 318px;
}
  border-radius: 100%;
`

const BgImage7 = styled(ImgWrapper)`
  position: absolute;
  width: 96px;
  height: 96px;
  right: 25%;
  top: 383px;

${When.Medium} {
  display: none;
}
  border-radius: 100%;
`

const OverlayModal = styled.div`
position: fixed;
background: #212121F2;
width: 100vw;
height: 100vh;
z-index: 1000;
transition: transform 400ms ease-in-out;
transition: visibility 400ms ease-in-out, background 400ms ease-in-out;
${({ isOpen }) => (isOpen ? '' : `
background: transparent;
visibility: hidden;
`)}
`

const ModalContents = styled.div`
transition: transform 400ms ease-in-out;
margin-left: auto;
height: 100%;
background: #fff;
${({ isOpen }) => (isOpen ? 'transform: translateX(0);' : 'transform: translateX(100%);')}
  ${When.Large}{
    max-width: 560px;
  }
`

const ModalHeader = styled.div`
height: 80px;
background: #F5F5F5;
display: flex;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
padding: 24px 17px;
`

const ModalHeaderTitle = styled.h2`
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 27px;
color: #212121;
`

const DecorationsWrapper = styled.div`
  height: calc(100% - 80px);
  background: #fff;
  padding-right: 12px;
`

const DeocrationsContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding-top:24px;
  padding-bottom: 80px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9E9E9E;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9E9E9E;
  }
  ${When.Medium}{
    padding-top:28px;
  }
  ${When.Large}{
    padding-top: 48px;
  }
`

const Decoration = styled.div`
display: flex;
flex-wrap: wrap;
max-width: 450px;
margin-top: 24px;
&:last-of-type {
  margin-bottom: 40px;
}
&:first-of-type {
  margin-top: 40px;
}
padding-left: 32px;
${When.Medium}{
  margin-top: 32px;
  &:last-of-type {
    margin-bottom: 32px;
  }
  &:first-of-type {
    margin-top: 24px;
  }
}
${When.Large}{
  margin-top: 32px;
  &:last-of-type {
    margin-bottom: 32px;
  }
  &:first-of-type {
    margin-top: 32px;
  }
}
`

const DecorationImage = styled(Img)`
  min-width: 176px;
  min-height: 176px;
  max-width: 176px;
  max-height: 176px;
  border-radius: 100%;
`

const DecorationTitleDescContainer = styled.div`
  min-width: 240px;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`

const DecorationTitle = styled.h3`
margin-top: 20px;
margin-bottom: 12px;
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 18px;
color: #212121;
`

const DecorationDescription = styled.p`
margin: 0;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
color: #212121;
`

export default function Decorations({ location }) {
  const {
    allStrapiDecorationsBackgroundImages: { edges: [{ node }] },
    allStrapiDecorations: { edges: decorationsNodes }
  } = useStaticQuery(query)

  const [modalOpen, setModalOpen] = useState(false);

  const bgImages = useMemo(() => {
    const res = {};
    for (let [key, value] of Object.entries(node)) {
      res[key] = {
        id: key,
        url: value.url,
        fluid: value.localFile.childImageSharp.fluid,
      }
    }
    return res;
  }, node)

  const decorations = useMemo(() => {
    return decorationsNodes.map(e => e.node);
  }, decorationsNodes)


  return (
    <>
      <OverlayModal id="overlay-modal" isOpen={modalOpen} onClickCapture={(e) => {
        e.persist();
        if (e.target.id === "overlay-modal") {
          setModalOpen(false);
        }
      }}>
        <ModalContents isOpen={modalOpen}>
          <ModalHeader>
            <ModalHeaderTitle>
              Decorations
          </ModalHeaderTitle>
            <svg onClick={() => setModalOpen(false)} style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6 6L18 18" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </ModalHeader>
          <DecorationsWrapper>
            <DeocrationsContainer>
              {decorations.map((decoration) => (
                <Decoration key={decoration.id}>
                  {decoration.photo ?
                    <DecorationImage fluid={decoration.photo.localFile.childImageSharp.fluid} />
                    :
                    null
                  }
                  <DecorationTitleDescContainer>
                    <DecorationTitle>
                      {decoration.title}
                    </DecorationTitle>
                    <DecorationDescription>
                      {decoration.description}
                    </DecorationDescription>
                  </DecorationTitleDescContainer>
                </Decoration>
              ))}
            </DeocrationsContainer>
          </DecorationsWrapper>
        </ModalContents>
      </OverlayModal>
      <Layout location={location} style={{ minHeight: 0}}>
        <DecorationsContainer >
          <BgImage1 onClick={() => setModalOpen(true)} fluid={bgImages?.leftTop.fluid} style={{ position: "absolute", cursor: 'pointer' }} />
          <BgImage2 onClick={() => setModalOpen(true)} fluid={bgImages?.leftMid.fluid} style={{ position: "absolute", cursor: 'pointer' }} />
          <BgImage3 onClick={() => setModalOpen(true)} fluid={bgImages?.leftBot.fluid} style={{ position: "absolute", cursor: 'pointer' }} />
          <BgImage4 onClick={() => setModalOpen(true)} fluid={bgImages?.rightTop.fluid} style={{ position: "absolute", cursor: 'pointer' }} />
          <BgImage5 onClick={() => setModalOpen(true)} fluid={bgImages?.rightMid.fluid} style={{ position: "absolute", cursor: 'pointer' }} />
          <BgImage6 onClick={() => setModalOpen(true)} fluid={bgImages?.rightBot.fluid} style={{ position: "absolute", cursor: 'pointer' }} />
          <BgImage7 onClick={() => setModalOpen(true)} fluid={bgImages?.mobileTop.fluid} style={{ position: "absolute", cursor: 'pointer' }} />
          <Title>Decoration</Title>
          <SubTitle>We create and decorate innovative glass packaging</SubTitle>
          <LearnMoreAndArrow>
            <LearnMoreWrapper>
              <ArrowRight stroke={black} width={6} height={12} />
            </LearnMoreWrapper>
            <LearnMoreLink onClick={() => setModalOpen(true)}>Learn More</LearnMoreLink>
          </LearnMoreAndArrow>
          <SliderContainer>
            <DecorationsSlider showSliderItems={1} onItemClick={() => setModalOpen(true)} />
          </SliderContainer>
        </DecorationsContainer>
        {/* <ComingSoon /> */}
      </Layout>
    </>
  )
}


const query = graphql`
  query {
    allStrapiDecorationsBackgroundImages {
      edges {
        node {
          leftTop {
            url
          }
          leftTop {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                },
              }
            }
          }
          leftMid {
            url
          }
          leftMid {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                },
              }
            }
          }
          leftBot {
            url
          }
          leftBot {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                },
              }
            }
          }
          rightTop {
            url
          }
          rightTop {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                },
              }
            }
          }
          rightMid {
            url
          }
          rightMid {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                },
              }
            }
          }
          rightBot {
            url
          }
          rightBot {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                },
              }
            }
          }
          mobileTop {
            url
          }
          mobileTop {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                },
              }
            }
          }
        }
      }
    }
    allStrapiDecorations(sort: {fields: orderNumber, order: ASC}) {
      edges {
        node {
          id
          photo {
            url
          }
          photo {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          description
          title
          alternativeForPhoto,
        }
      }
    }
  }
`